import BaseModel from "@/models/base-model.js";

export default class Customer extends BaseModel {
  fields = {
    name: {
      type: "text",
      label: "nama",
      title: "Nama",
      minLen: 3,
      validationRules: {
        required: true,
        minLength: true,
      },
    },
    customer_category_id: {
      type: "select",
      title: "Kategori Pelanggan",
      label: "customer_category_id",
      url: process.env.VUE_APP_API_BASE_URL + "customer-categories",
      validationRules: {
        required: false,
      },
    },
    email: {
      type: "text",
      label: "email",
      title: "E-mail",
      validationRules: {
        required: false,
        email: false,
      },
    },
    address: {
      title: "Alamat",
      type: "textarea",
      label: "address",
      minLen: 5,
      validationRules: {
        required: false,
        minLength: false,
      },
    },
    phone_number: {
      title: "No.Hp",
      type: "phone",
      label: "phone_number",
      minLen: 11,
      validationRules: {
        required: false,
        minLength: false,
      },
    },
  };
  endpoint = process.env.VUE_APP_API_BASE_URL + "customers";
}
