<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customer from "@/models/customer.js";
import detailGroupInput from "@/components/detail-group-input.vue";
import loadingVue from "@/components/loading.vue";

const customerModel = new customer();

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, detailGroupInput, loadingVue },
  page: {
    title: "Detail Produk Kategori",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Produk",
        },
        {
          text: "Kategori",
          href: "/",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      rowCount: 10,
      isLoading: false,
      setData: [],
      columns: [
        {
          value: "name",
          label: "Nama",
        },
        {
          value: "email",
          label: "E-mail",
          textDefault: true,
        },
        {
          value: "phone_number",
          label: "No. Hp",
        },
        {
          value: "customer_category.name",
          label: "Kategori",
        },
        {
          value: "province.name",
          label: "Provinsi",
        },
        {
          value: "regency.name",
          label: "Kabupaten / Kota",
        },
        {
          value: "district.name",
          label: "Kecamatan",
        },
        {
          value: "village.name",
          label: "Desa",
        },
        {
          value: "updated_at",
          label: "Tanggal di Perbarui",
        },
        {
          value: "created_at",
          label: "Tanggal di Buat",
        },
      ],
    };
  },
  watch: {
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.isLoading = true;
      customerModel
        .find(this.$route.params.id)
        .then((resp) => {
          if (resp.length !== 0) {
            // console.log('ada')
            this.setData = resp;
            this.isLoading = false;
          } else {
            // console.log('tidak')
            this.$router.push("/404-notfound");
          }
          // this.setData = resp;
          // this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status == 404) {
            this.$router.push("/404-notfound");
          }
          this.isLoading = false;
        });
    },
    returnBack() {
      return this.$router.go(-1);
      // return this.$router.push("/produk/warna");
    },
  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <b-row v-if="!isLoading">
          <detail-group-input :columns="columns" :data="setData" />
        </b-row>
        <b-row v-else>
          <b-col class="my-3" sm="6" md="4" v-for="row in rowCount" :key="row">
            <loadingVue
              class="my-2"
              width="50%"
              height="1.5rem"
              borderRadius="7px"
            />
            <loadingVue width="100%" height="2rem" borderRadius="7px" />
          </b-col>
        </b-row>
        <b-btn
          variant="success"
          @click="returnBack"
          class="ml-auto text-white"
          style="
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          ><i class="uil-arrow-left"></i> Kembali
        </b-btn>
      </b-card-body>
    </b-card>
  </Layout>
</template>
